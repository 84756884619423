import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  ConfirmationDialogConfig,
  MaterialThemeColor,
} from "./ConfirmationDialogConfig";

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.scss"],
})
export class ConfirmationDialogComponent implements OnInit {
  message: string;
  noButtonColor: MaterialThemeColor;
  yesButtonColor: MaterialThemeColor;

  constructor(
    private _dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private _data: ConfirmationDialogConfig,
  ) {
    _dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.message = this._data.message;
    this.noButtonColor = this._data.noButtonColor;
    this.yesButtonColor = this._data.yesButtonColor || "primary";
  }

  responseHandler(response: boolean) {
    this._dialogRef.close(response);
  }
}
