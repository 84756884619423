import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Feedback, FeedbackForm, FeedbackJson } from "../../../models/Feedback";
import { FeedbackService } from "../../services/feedback.service";
import { MatDialogRef } from "@angular/material/dialog";
import { SnackBarService } from "../../../core/services/snack-bar.service";

@Component({
  selector: "app-feedback-dialog",
  templateUrl: "./feedback-dialog.component.html",
  styleUrls: ["./feedback-dialog.component.scss"],
})
export class FeedbackDialogComponent implements OnInit {
  feedbackFormGroup: FormGroup<FeedbackForm>;
  disableSubmitButton = false;

  constructor(
    private _feedbackService: FeedbackService,
    private _dialogRef: MatDialogRef<FeedbackDialogComponent>,
    private _snackbarService: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.feedbackFormGroup = new Feedback(null).buildFormGroup();
  }

  submitFeedbackForm() {
    this.disableSubmitButton = true;
    const feedbackJson = this.feedbackFormGroup.value as FeedbackJson;
    this._feedbackService.sendEmail(feedbackJson).subscribe({
      next: (response) => {
        if (response) {
          this._dialogRef.close();
          this._snackbarService.success("Feedback sent successfully");
        }
      },
    });
  }
}
