/* eslint-disable no-constant-condition,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access */
import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, throwError } from "rxjs";
import { SnackBarService } from "../services/snack-bar.service";

export const SKIP_INTERCEPTOR = new HttpContextToken(() => false);

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private _snackBarService: SnackBarService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (request.context.get(SKIP_INTERCEPTOR)) return next.handle(request);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let message = "Something went wrong!!";
        if (this.isRestRuntimeError(error)) {
          if (error.error !== null || true) {
            if (error.error.message === "cycle detected") {
              const cycle = (error.error.data["cycle"] as string[]).join(
                " -> ",
              );
              message = "Cycle detected: " + cycle;
            } else {
              message = error.error.message
                ? error.error.message
                : error.error.ERROR_TYPE;
            }
          }
        }
        this._snackBarService.error(message);
        console.error("http error", error);
        return throwError(() => error);
      }),
    );
  }

  private isRestRuntimeError(error: HttpErrorResponse) {
    return (
      error.status === HttpStatusCode.BadRequest ||
      error.status === HttpStatusCode.NotFound
    );
  }
}
