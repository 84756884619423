import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "combineToolTips" })
export class CombineToolTips implements PipeTransform {
  /*
   * This function mainly taking a list of string and return a formatted string,
   * which will be visualized as Tooltips.
   *
   * @param the list of string tips
   * @return a formattedTips as string
   * */
  transform(tips: string[]): string {
    let formattedTips = "";

    tips.forEach((value) => {
      formattedTips += "* " + value + "\n";
    });

    return formattedTips;
  }
}
