import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  FeedbackForm,
  FeedbackFormMember,
  FeedbackJson,
} from "../../../models/Feedback";

@Component({
  selector: "app-feedback-form",
  templateUrl: "./feedback-form.component.html",
  styleUrls: ["./feedback-form.component.scss"],
})
export class FeedbackFormComponent {
  @Input() feedbackFormGroup: FormGroup<FeedbackForm>;
  @Output() formSubmitted: EventEmitter<FeedbackJson> =
    new EventEmitter<FeedbackJson>();
  FeedbackFormMember = FeedbackFormMember;

  onSubmit() {
    if (this.feedbackFormGroup.invalid) return;
    this.formSubmitted.emit(this.feedbackFormGroup.value as FeedbackJson);
  }
}
