import { Component, Input } from "@angular/core";
import { Breadcrumb } from "src/app/models/Breadcrumb";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent {
  @Input() breadcrumbs: Breadcrumb[];
}
