<form
  (ngSubmit)="onSubmit()"
  [formGroup]="feedbackFormGroup"
  class="flex flex-col"
  id="submitFeedbackForm"
>
  <mat-form-field appearance="outline">
    <mat-label> {{'feedback.title' | translate}}</mat-label>
    <input
      [formControlName]="FeedbackFormMember.Title"
      aria-label="subject"
      placeholder="Enter Your Feedback Title"
      autocomplete="off"
      matInput
    />
    <mat-error
      *ngIf="feedbackFormGroup?.get(FeedbackFormMember.Title).hasError('required')"
    >
      {{'feedback.title' | translate}} is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label> {{ 'feedback.feedback' | translate }}</mat-label>
    <textarea
      [formControlName]="FeedbackFormMember.feedback"
      aria-label="Feedback"
      placeholder="Enter Your Feedback"
      autocomplete="off"
      matInput
      rows="5"
      type="text"
    ></textarea>
    <mat-error
      *ngIf="feedbackFormGroup?.get(FeedbackFormMember.feedback).hasError('required')"
    >
      {{'feedback.feedback' | translate}} is required.
    </mat-error>
  </mat-form-field>
</form>
