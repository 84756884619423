<h2 mat-dialog-title class="text-center">Connected Entities</h2>
<mat-dialog-content>
  <table>
    <tbody>
      <tr>
        <th>Type</th>
        <th>Code</th>
      </tr>
      <ng-container
        *ngFor="let sEntityTypeCodesMap of sEntityTypeCodesMap | keyvalue"
      >
        <tr>
          <td [attr.rowspan]="sEntityTypeCodesMap.value.length + 1">
            {{sEntityTypeCodesMap.key}}
          </td>
        </tr>
        <tr *ngFor="let code of sEntityTypeCodesMap.value">
          <td>{{code}}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <p class="mt-2">
    You have to disconnect the above entities before the deletion.
  </p>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="closeDialog()">
    Close
  </button>
</div>
