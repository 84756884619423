<ul>
  <li *ngFor="let breadcrumb of breadcrumbs">
    <a
      mat-button
      [routerLink]="breadcrumb.url"
      [disabled]="breadcrumb.disabled"
      class="px-0"
      >{{ breadcrumb.label }}</a
    >
  </li>
</ul>
