import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FeedbackJson } from "../../models/Feedback";
import { ApiPrefix } from "../../core/services/api-prefix";
import { ApiConstants } from "../../core/services/api-constants";
import { DashboardApiConstants } from "../../dashboard/services/dashboard-api-constants";

@Injectable({
  providedIn: "root",
})
export class FeedbackService {
  apiPrefix = ApiPrefix.value;
  feedbackBasePath = `${this.apiPrefix}${ApiConstants.api}/${DashboardApiConstants.feedbacks}`;

  constructor(private _http: HttpClient) {}

  sendEmail(feedbackJson: FeedbackJson) {
    return this._http.post<FeedbackJson>(this.feedbackBasePath, feedbackJson);
  }
}
