import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FormInterface } from "./IForm";

export class Feedback implements FormInterface {
  title: string;
  feedback: string;

  constructor(jsonData: FeedbackJson | null) {
    if (!jsonData) return;
    this.title = jsonData.title;
    this.feedback = jsonData.feedback;
  }

  buildFormGroup(model?: Feedback): FormGroup<FeedbackForm> {
    const tempFeedback = model ? model : this;
    return new FormGroup({
      title: new FormControl(tempFeedback.title, Validators.required),
      feedback: new FormControl(tempFeedback.feedback, Validators.required),
    });
  }

  trimFromGroup(formGroup: FormGroup<FeedbackForm>): FormGroup<FeedbackForm> {
    return formGroup;
  }
}

export enum FeedbackFormMember {
  Title = "title",
  feedback = "feedback",
}

export interface FeedbackJson {
  title: string;
  feedback: string;
}

export interface FeedbackForm {
  title: FormControl<string>;
  feedback: FormControl<string>;
}
