import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Organization, OrganizationJson } from "src/app/models/Organization";
import { User, UserJson, SupportedLanguage } from "../../models/User";
import { ApiConstants } from "./api-constants";
import { ApiPrefix } from "./api-prefix";

@Injectable({
  providedIn: "root",
})
export class UserService {
  baseUrl = `${ApiPrefix.value}${ApiConstants.api}/${ApiConstants.users}`;
  currentUserUrl = `${this.baseUrl}/me`;

  constructor(private http: HttpClient) {}

  getLoggedInUser(): Observable<UserJson> {
    return this.http.get<UserJson>(this.currentUserUrl);
  }

  storeUserCredentials(userInfo: User): void {
    localStorage.setItem(LocalStorageItem.UserId, userInfo.id.toString());
    localStorage.setItem(LocalStorageItem.Username, userInfo.username || "");
    localStorage.setItem(LocalStorageItem.Roles, userInfo.roles.toString());
    localStorage.setItem(LocalStorageItem.Uuid, userInfo.uuid || "");
    localStorage.setItem(LocalStorageItem.Email, userInfo.email || "");
    localStorage.setItem(LocalStorageItem.Language, userInfo.language || "");
    localStorage.setItem(
      LocalStorageItem.ORAGNIZATION,
      JSON.stringify(userInfo.organization) || "",
    );
  }

  getLoggedInUserCredentials(): User {
    const atmetaxUser = new User(null);

    const userId = localStorage.getItem(LocalStorageItem.UserId);
    const roles = localStorage.getItem(LocalStorageItem.Roles);

    atmetaxUser.id = userId ? +userId : 0;
    atmetaxUser.username = localStorage.getItem(LocalStorageItem.Username);
    atmetaxUser.roles = roles ? roles.split(",") : [];
    atmetaxUser.uuid = localStorage.getItem(LocalStorageItem.Uuid);
    atmetaxUser.email = localStorage.getItem(LocalStorageItem.Email);
    atmetaxUser.language = localStorage.getItem(
      LocalStorageItem.Language,
    ) as SupportedLanguage;
    atmetaxUser.organization = new Organization(
      JSON.parse(
        localStorage.getItem(LocalStorageItem.ORAGNIZATION),
      ) as OrganizationJson,
    );
    return atmetaxUser;
  }

  updateUserLanguage(language: SupportedLanguage) {
    const languageUrl = this.currentUserUrl + "/language";
    this.http
      .patch(languageUrl, {
        ...this.getLoggedInUserCredentials(),
        language,
      })
      .subscribe({
        next: (userJson: UserJson) => {
          localStorage.setItem(LocalStorageItem.Language, userJson.language);
        },
      });
  }

  getUserLanguage(): SupportedLanguage {
    return localStorage.getItem(LocalStorageItem.Language) as SupportedLanguage;
  }

  getCanvasHelpDialogPopupStatus(): boolean {
    const dontShowAgain = localStorage.getItem("canvas-help-guide-show");
    return !!dontShowAgain;
  }

  toggleCanvasDialogPopup(): void {
    const dontShowAgain = localStorage.getItem("canvas-help-guide-show");
    if (dontShowAgain) {
      localStorage.removeItem("canvas-help-guide-show");
    } else {
      localStorage.setItem("canvas-help-guide-show", "true");
    }
  }

  /**
   * sends a request to the specified url from a form. this will change the window location.
   *
   * <p>
   * We perform regular form submission to avoid XHR request because that would cause CORS header 'Access-Control-Allow-Origin' missing
   * We then have to give special CORS header to auth server, So we skipped this approach.
   * </p>
   * <p>
   * This is not vulnerable to HTTP BREACH attack. because:
   * -> response-body doesn't have csrf-token
   * -> attacker cannot control the input, there is nothing to send
   * -> no attacker input will be sent as response body, so attacker could guess about any secret
   * -> without XSRF-TOKEN this form submit will fail, no external javascript can access this client side cookie
   * </p>
   * @param {string} path the path to send the post request to
   * @param {string} [method=post] the method to use on the form
   */
  logoutUser(path = "/logout", method = "post") {
    // https://stackoverflow.com/questions/133925/javascript-post-request-like-a-form-submit

    const form = document.createElement("form");
    form.method = method;
    form.action = path;

    const hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "_csrf";
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    hiddenField.value = this.getCookie("XSRF-TOKEN");

    form.appendChild(hiddenField);

    document.body.appendChild(form);
    form.submit();
  }

  /**
   * get client side cookie having {@link cname}
   * @param cname cookie name
   * @returns cookie value
   */
  getCookie(cname: string) {
    // https://www.w3schools.com/js/js_cookies.asp
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  getUserList() {
    return this.http.get<UserJson[]>(this.baseUrl);
  }

  switchUser(username: string) {
    const url = ApiPrefix.value + "/login/impersonate?username=" + username;
    return this.http.post(url, null);
  }
}

export const enum LocalStorageItem {
  UserId = "atmetax_userId",
  Username = "atmetax_username",
  Roles = "atmetax_roles",
  Uuid = "atmetax_uuid",
  Email = "atmetax_email",
  Language = "atmetax_language",
  ORAGNIZATION = "atmetax_organization",
}
