import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SchemaEntityInfoDialogConfig } from "./schema-entity-info-dialog.config";

@Component({
  selector: "app-schema-entity-info-dialog",
  templateUrl: "./schema-entity-info-dialog.component.html",
  styleUrls: ["./schema-entity-info-dialog.component.scss"],
})
export class SchemaEntityInfoDialogComponent implements OnInit {
  sEntityTypeCodesMap: Map<string, string>;
  constructor(
    private _dialogRef: MatDialogRef<SchemaEntityInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private _config: SchemaEntityInfoDialogConfig,
  ) {}

  ngOnInit(): void {
    this.sEntityTypeCodesMap = this._config.message;
  }

  public closeDialog() {
    this._dialogRef.close();
  }
}
