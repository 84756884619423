import { Component, OnInit } from "@angular/core";
import { atmetaxAuthLink } from "src/app/core/core.module";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginLink = atmetaxAuthLink;
  loginFailed = false;

  ngOnInit(): void {
    this.loginFailed = window.location.href.includes("login?error");
  }
}
