import { Injector, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "./material/material.module";
import { AppHeaderComponent } from "./components/app-header/app-header.component";
import { LayoutModule } from "@angular/cdk/layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { TranslateModule } from "@ngx-translate/core";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { RouterModule } from "@angular/router";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";
import { CombineToolTips } from "./pipes/CombineToolTips";
import { PascalToRegularCaseConverter } from "./pipes/PascalToRegularCaseConverter";
import { FeedbackDialogComponent } from "./components/feedback-dialog/feedback-dialog.component";
import { FeedbackFormComponent } from "./components/feedback-form/feedback-form.component";
import { SchemaEntityInfoDialogComponent } from "./components/schema-entity-info-dialog/schema-entity-info-dialog.component";

/**
 * Do declare components, directives, and pipes in a shared module when those items will be re-used and referenced
 * by the components declared in other feature modules.
 *
 * Consider using the name SharedModule when the contents of a shared module are referenced across the entire application.
 *
 * https://angular.io/guide/styleguide#shared-feature-module
 */
@NgModule({
  declarations: [
    AppHeaderComponent,
    SpinnerComponent,
    BreadcrumbComponent,
    ConfirmationDialogComponent,
    FeedbackDialogComponent,
    FeedbackFormComponent,
    CombineToolTips,
    PascalToRegularCaseConverter,
    SchemaEntityInfoDialogComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    // When we add translation in any component inside SharedModule it will be necessary to import TranslateModule here.
    // See documentation here: https://github.com/ngx-translate/core#sharedmodule
    TranslateModule.forChild(),
    RouterModule,
  ],
  exports: [
    AppHeaderComponent,
    SpinnerComponent,
    BreadcrumbComponent,
    MaterialModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    DragDropModule,
    CombineToolTips,
    PascalToRegularCaseConverter,
  ],
})
export class SharedModule {
  static injector: Injector;
  constructor(private _injector: Injector) {
    SharedModule.injector = _injector;
  }
}
