export class BaseModel {
  id = 0;
  version = 0;
  modifiedDate: Date | null;
  createdDate: Date | null;

  constructor(jsonData: BaseJson | null) {
    this.id = jsonData?.id || this.id;
    this.version = jsonData?.version || this.version;

    this.modifiedDate = jsonData?.modifiedDate
      ? new Date(jsonData.modifiedDate)
      : null;

    this.createdDate = jsonData?.createdDate
      ? new Date(jsonData.createdDate)
      : null;
  }
}

export interface BaseJson {
  id?: number;
  version?: number;
  modifiedDate?: Date;
  createdDate?: Date;
}
