import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { map, shareReplay, tap } from "rxjs/operators";
import { SupportedLanguage, User } from "./models/User";
import { UserService } from "./core/services/user.service";
import { ThemeService } from "./core/services/theme.service";
import { IsActiveMatchOptions } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { FeedbackDialogComponent } from "./shared/components/feedback-dialog/feedback-dialog.component";

interface NavItem {
  url: string;
  displayText: string;
  routerLinkActiveOptions: { exact: boolean } | IsActiveMatchOptions;
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "AtMetax";
  authenticated = false;
  currentUser: User | null = null;
  isSidenavOpened = true;
  logoutButtonText = "Logout";
  navItems: NavItem[] = [
    {
      url: "/",
      displayText: "Dashboard",
      routerLinkActiveOptions: { exact: true },
    },
  ];

  domainAdminNavItems: NavItem[] = [
    {
      url: "/admin/users",
      displayText: "Users",
      routerLinkActiveOptions: { exact: false },
    },
  ];

  adminNavItems: NavItem[] = [
    {
      url: "/threejs",
      displayText: "Flat Roof",
      routerLinkActiveOptions: { exact: true },
    },
    {
      url: "/admin/building-functions",
      displayText: "Building Functions",
      routerLinkActiveOptions: { exact: true },
    },
    {
      url: "/admin/formula-units",
      displayText: "Formula Unit",
      routerLinkActiveOptions: { exact: true },
    },
    {
      url: "/admin/schemas",
      displayText: "Schema List",
      routerLinkActiveOptions: { exact: false },
    },
    {
      url: "/admin/building-element-prices",
      displayText: "Price List",
      routerLinkActiveOptions: { exact: false },
    },
    {
      url: "/admin/dashboard-questions",
      displayText: "Dashboard Questions",
      routerLinkActiveOptions: { exact: false },
    },
  ];

  isTablet$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Tablet)
    .pipe(
      map((result) => result.matches),
      tap((isTablet) => (this.isSidenavOpened = !isTablet)),
      shareReplay(),
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private userService: UserService,
    private translate: TranslateService,
    public themeService: ThemeService,
    private _dialog: MatDialog,
  ) {
    translate.addLangs(["nl", "en"]);
  }

  ngOnInit(): void {
    this.userService.getLoggedInUser().subscribe((data) => {
      this.currentUser = new User(data);
      this.userService.storeUserCredentials(this.currentUser);
      this.authenticated = true;
      this.translate.use(this.currentUser.language);

      if (this.currentUser.isAdmin) {
        this.navItems = [...this.navItems, ...this.adminNavItems];
        this.navItems = [...this.navItems, ...this.domainAdminNavItems];
      }
      if (this.currentUser.isDomainAdmin) {
        this.navItems = [...this.navItems, ...this.domainAdminNavItems];
      }

      if (this.currentUser.roles.includes("ROLE_PREVIOUS_ADMINISTRATOR"))
        this.logoutButtonText = "Return to Previous User";
      else this.logoutButtonText = "Logout";
    });
  }

  toggleSidenav(): void {
    this.isSidenavOpened = !this.isSidenavOpened;
  }

  useLanguage(language: SupportedLanguage): void {
    this.userService.updateUserLanguage(language);
    this.currentUser.language = language;
    this.translate.use(language);
  }

  openFeedbackDialog() {
    this._dialog.open(FeedbackDialogComponent, {
      width: "50%",
    });
  }

  logoutUser() {
    if (this.currentUser.isSwitchedUser)
      this.userService.logoutUser("/logout/impersonate");
    else this.userService.logoutUser();
  }
}
