<ng-container *ngIf="authenticated; else loginBlock">
  <mat-toolbar class="app-sticky-toolbar mat-elevation-z6" color="primary">
    <button
      type="button"
      aria-label="Toggle sidenav"
      mat-icon-button
      (click)="toggleSidenav()"
      *ngIf="isTablet$ | async"
    >
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>

    <span>{{title}}</span>

    <button mat-button [matMenuTriggerFor]="languageMenu" class="ml-auto">
      <mat-icon class="flag {{ currentUser.language }}"></mat-icon>
    </button>
    <mat-menu #languageMenu="matMenu">
      <button mat-menu-item (click)="useLanguage('nl')">
        <mat-icon class="flag nl"></mat-icon>
        Dutch
      </button>
      <button mat-menu-item (click)="useLanguage('en')">
        <mat-icon class="flag en"></mat-icon>
        English
      </button>
    </mat-menu>

    <button
      (click)="themeService.toggleDarkMode()"
      type="button"
      aria-label="Toggle Dark Mode"
      title="Toggle Dark Mode"
      mat-icon-button
    >
      <mat-icon aria-label="Toggle Dark Mode"
        >{{themeService.isDark ? "dark_mode" : "light_mode"}}</mat-icon
      >
    </button>

    <button mat-button [matMenuTriggerFor]="userMenu" aria-label="Current user">
      <mat-icon>person</mat-icon>
      {{currentUser?.username}}
    </button>

    <mat-menu #userMenu="matMenu">
      <button mat-menu-item (click)="logoutUser()">
        <mat-icon color="warn">logout</mat-icon>
        <span>{{ logoutButtonText }}</span>
      </button>
    </mat-menu>
  </mat-toolbar>

  <mat-sidenav-container class="app-sidenav-container">
    <!-- For responsive -->
    <mat-sidenav
      *ngIf="(isTablet$ | async)"
      class="app-sidenav"
      [attr.role]="'dialog'"
      [mode]="'over'"
      [(opened)]="isSidenavOpened"
      role="navigation"
    >
      <div class="h-100">
        <mat-nav-list>
          <a
            *ngFor="let navItem of navItems"
            mat-list-item
            [routerLink]="navItem.url"
            routerLinkActive="active"
            [routerLinkActiveOptions]="navItem.routerLinkActiveOptions"
          >
            {{navItem.displayText}}
          </a>
        </mat-nav-list>
        <button
          class="mb-1"
          (click)="openFeedbackDialog()"
          type="button"
          color="primary"
          mat-raised-button
        >
          {{ 'feedback.nav-button-title' | translate }}
        </button>
      </div>
    </mat-sidenav>

    <mat-sidenav-content>
      <!-- outer container which holds left panel and inner container -->
      <div class="h-full min-h-full min-w-full w-full flex flex-row">
        <!-- left panel -->
        <nav
          *ngIf="(isTablet$ | async) === false"
          class="flex flex-col justify-between items-center app-sidenav-inside-content"
          role="navigation"
        >
          <mat-nav-list class="w-100">
            <a
              *ngFor="let navItem of navItems"
              mat-list-item
              [routerLink]="navItem.url"
              routerLinkActive="active"
              [routerLinkActiveOptions]="navItem.routerLinkActiveOptions"
            >
              {{navItem.displayText}}
            </a>
          </mat-nav-list>
          <button
            class="mb-1 fixed-button"
            (click)="openFeedbackDialog()"
            type="button"
            color="primary"
            mat-raised-button
          >
            {{ 'feedback.nav-button-title' | translate }}
          </button>
        </nav>

        <!-- inner container which holds main content -->
        <main class="flex flex-col flex-1">
          <!-- Add Content Here -->
          <router-outlet></router-outlet>
        </main>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <app-footer class="mt-auto"></app-footer>
</ng-container>

<ng-template #loginBlock>
  <app-login></app-login>
</ng-template>
