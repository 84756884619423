<h2 mat-dialog-title>Confirmation!</h2>
<mat-dialog-content>{{ message }}</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-raised-button
    [color]="noButtonColor"
    (click)="responseHandler(false)"
  >
    No
  </button>
  <button
    mat-raised-button
    [color]="yesButtonColor"
    (click)="responseHandler(true)"
  >
    Yes
  </button>
</mat-dialog-actions>
