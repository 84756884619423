<div
  class="flex flex-col justify-between items-center h-full min-h-full min-w-full w-full"
>
  <h1>Atmetax</h1>
  <a mat-raised-button color="primary" *ngIf="!loginFailed" href="{{loginLink}}"
    >Sign in with Atmetis Connect</a
  >

  <h1 *ngIf="loginFailed">Sign In Failed!</h1>
</div>
