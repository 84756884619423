export class DashboardApiConstants {
  static sessions = "sessions";
  static dashboardquestionresponses = "dashboard-question-responses";
  static schemaquestionresponses = "question-responses";
  static users = "users";
  static questionVisibilities = "question-visibilities";
  static schemas = "schemas";
  static rebuildingCost = "rebuildingCost";
  static formulaResponse = "formula-response";
  static sessionSchemaEntityValues = "entity-values";
  static feedbacks = "feedbacks";
}
