import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpAuthErrorInterceptor } from "./http-auth-error.interceptor";
import { HttpErrorInterceptor } from "./http-error.interceptor";

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpAuthErrorInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true,
  },
];
