import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import {
  FullscreenOverlayContainer,
  OverlayContainer,
} from "@angular/cdk/overlay";
import { registerLocaleData } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import localeNL from "@angular/common/locales/nl";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { UserService } from "./core/services/user.service";
import { FooterComponent } from "./layouts/footer/footer.component";
import { LoginComponent } from "./layouts/login/login.component";
import { SharedModule } from "./shared/shared.module";
import { DBConfig, NgxIndexedDBModule } from "ngx-indexed-db";
import {
  NGX_CURRENCY_CONFIG,
  NgxCurrencyConfig,
  NgxCurrencyDirective,
  NgxCurrencyInputMode,
} from "ngx-currency";
import { MatIconRegistry } from "@angular/material/icon";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

/**
 * setup locale for currency pipe
 * https://angular.io/api/common/CurrencyPipe
 * https://angular.io/guide/i18n#setting-up-the-locale-of-your-app
 * https://www.angularjswiki.com/angular/angular-currency-pipe-formatting-currency-in-angular/
 */
registerLocaleData(localeNL, "nl");

// Currency input default config
export const customCurrencyMaskConfig: NgxCurrencyConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "€ ",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: NgxCurrencyInputMode.Natural,
};

const DB_NAME = "atmetax_db";

export function migrationFactory() {
  return {
    2: (db: IDBDatabase, transaction: IDBTransaction) => {
      const store = transaction.objectStore(BUILDING_CANVAS_TABLE);
      store.createIndex("sessionCode", "sessionCode", { unique: false });
      store.deleteIndex("sessionId");
    },
  };
}
export const BUILDING_CANVAS_TABLE = "building_canvas";
const dbConfig: DBConfig = {
  name: DB_NAME,
  version: 2,
  objectStoresMeta: [
    {
      store: BUILDING_CANVAS_TABLE,
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "id", keypath: "id", options: { unique: true } },
        {
          name: "sessionId",
          keypath: "sessionId",
          options: { unique: false },
        },
        { name: "schemaId", keypath: "schemaId", options: { unique: false } },
        { name: "partNo", keypath: "partNo", options: { unique: false } },
        { name: "image", keypath: "image", options: { unique: false } },
        { name: "createdAt", keypath: "createdAt", options: { unique: false } },
        { name: "updatedAt", keypath: "updatedAt", options: { unique: false } },
      ],
    },
  ],
  migrationFactory,
};

@NgModule({
  declarations: [AppComponent, LoginComponent, FooterComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxCurrencyDirective,
    NgxIndexedDBModule.forRoot(dbConfig),
  ],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [UserService],
      useFactory: (userService: UserService) => userService.getUserLanguage(),
    },
    // see: https://material.angular.io/cdk/overlay/overview#full-screen-overlays
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    { provide: NGX_CURRENCY_CONFIG, useValue: customCurrencyMaskConfig },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry) {
    // use angular material latest icon design angular material symbol
    // https://github.com/angular/components/issues/24845
    iconRegistry.setDefaultFontSetClass("material-symbols-outlined");
  }
}
