import { UntypedFormGroup } from "@angular/forms";
import { BaseModel, BaseJson } from "./BaseModel";
import { FormInterface } from "./IForm";

export class Organization extends BaseModel implements FormInterface {
  name: string | null;
  email: string | null;
  uuid: string | null;
  website: string | null;
  description: string | null;
  type: string | null;

  constructor(jsonData: OrganizationJson | null) {
    super(jsonData);
    this.name = jsonData?.name || null;
    this.email = jsonData?.email || null;
    this.uuid = jsonData?.uuid || null;
    this.website = jsonData?.website || null;
    this.description = jsonData?.description || null;
    this.type = jsonData?.type || null;
  }

  buildFormGroup(model?: any): UntypedFormGroup {
    // todo: implement it
    throw new Error("Method not implemented.");
  }

  trimFromGroup(formGroup: UntypedFormGroup): UntypedFormGroup {
    return formGroup;
  }
}

export interface OrganizationJson extends BaseJson {
  name?: string;
  email?: string;
  uuid?: string;
  website?: string;
  description?: string;
  type?: string;
}
