import { NumberInput } from "@angular/cdk/coercion";
import { Component, Input } from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";

/**
 * parent container div of this component should have "position: relative"
 * */
@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
})
export class SpinnerComponent {
  @Input() diameter: NumberInput = 100;
  @Input() value: NumberInput = 75;
  @Input() mode: ProgressSpinnerMode = "indeterminate";
  @Input() color: ThemePalette = "primary";
}
