import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  darkThemeCSSFile = "dark-theme.css"; // it must be matched with bundleName in "angular.json" file
  lightThemeCSSFile = "light-theme.css"; // it must be matched with bundleName in "angular.json" file

  darkThemeLinkElementClass = "atmetax-link__dark-theme"; // will be used to select link element from "document.head"
  lightThemeLinkElementClass = "atmetax-link__light-theme"; // will be used to select link element from "document.head"

  darkThemeClass = "dark-theme"; // it must be matched with class provided in "_dark-theme.scss" file

  isDark = false;

  constructor(@Inject(DOCUMENT) private document: Document) {
    if (localStorage.getItem("atmetax-theme")) {
      this.setDarkMode();
    } else {
      this.setLightMode();
    }
  }

  setDarkMode() {
    this.isDark = true;
    if (!this.getThemeLinkElementByClass(this.darkThemeLinkElementClass)) {
      this.createThemeLinkElement(
        this.darkThemeLinkElementClass,
        this.darkThemeCSSFile,
      );
    }
    this.document.body.classList.add(this.darkThemeClass);
  }

  setLightMode() {
    this.isDark = false;
    if (!this.getThemeLinkElementByClass(this.lightThemeLinkElementClass)) {
      this.createThemeLinkElement(
        this.lightThemeLinkElementClass,
        this.lightThemeCSSFile,
      );
    }
    this.document.body.classList.remove(this.darkThemeClass);
  }

  toggleDarkMode(): void {
    if (this.isDark) {
      localStorage.removeItem("atmetax-theme");
      this.setLightMode();
    } else {
      localStorage.setItem("atmetax-theme", "dark-theme");
      this.setDarkMode();
    }
  }

  createThemeLinkElement(
    linkElementClass: string,
    cssFile: string,
  ): HTMLLinkElement {
    const linkEl = this.document.createElement("link");
    linkEl.setAttribute("rel", "stylesheet");
    linkEl.setAttribute("href", cssFile);
    linkEl.classList.add(linkElementClass);
    this.document.head.appendChild(linkEl);
    return linkEl;
  }

  getThemeLinkElementByClass(className: string): HTMLLIElement {
    return this.document.head.querySelector(
      `link[rel="stylesheet"].${className}`,
    );
  }
}
