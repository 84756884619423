<div class="flex flex-row justify-between items-center">
  <h2 mat-dialog-title>{{ 'feedback.dialog-title' | translate }}</h2>
  <div>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content>
  <app-feedback-form
    [feedbackFormGroup]="feedbackFormGroup"
    (formSubmitted)="submitFeedbackForm()"
  ></app-feedback-form>
</mat-dialog-content>
<mat-dialog-actions class="flex flex-row justify-between items-center">
  <button mat-raised-button mat-dialog-close>
    {{"basic.cancel" | translate}}
  </button>
  <button
    [disabled]="disableSubmitButton"
    type="submit"
    form="submitFeedbackForm"
    mat-raised-button
    color="primary"
  >
    {{ disableSubmitButton ? 'Sending' : 'Send'}}
  </button>
</mat-dialog-actions>
