import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { OperationType } from "./operation-types";

@Injectable({
  providedIn: "root",
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  /**
   * This method notifies to the user when the operation is successfully done.
   *
   * @param message parameter capable of receiving a string or an enum type of data to show as
   *  snackBar message.
   *    Enum Details:
   *    OperationType.SAVE,OperationType.UPDATE,OperationType.REORDER
   * @param action parameter helps to add any action button in the snackBar.
   * @param config parameter provides us an opportunity to customize SnackBar.
   * */
  success(
    message: OperationType | string,
    action = "",
    config: MatSnackBarConfig = {
      duration: 3000,
      horizontalPosition: "end",
      panelClass: ["snackbar-success"],
    },
  ): void {
    if ((Object.values(OperationType) as string[]).includes(message))
      message = `${message} succeeded.`;
    this.snackBar.open(message, action, config);
  }

  error(
    message: string,
    action = "",
    config: MatSnackBarConfig = {
      duration: 3000,
      horizontalPosition: "end",
      panelClass: ["snackbar-error"],
    },
  ): void {
    this.snackBar.open(message, action, config);
  }

  warn(
    message: string,
    action = "",
    config: MatSnackBarConfig = {
      duration: 3000,
      horizontalPosition: "end",
      panelClass: ["snackbar-warn"],
    },
  ): void {
    this.snackBar.open(message, action, config);
  }

  info(
    message: string,
    action = "",
    config: MatSnackBarConfig = {
      duration: 3000,
      horizontalPosition: "end",
      panelClass: ["snackbar-info"],
    },
  ): void {
    this.snackBar.open(message, action, config);
  }
}
