import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { LocalStorageItem } from "../core/services/user.service";
import { BaseJson, BaseModel } from "./BaseModel";
import { FormInterface } from "./IForm";
import { Organization, OrganizationJson } from "./Organization";

export class User extends BaseModel implements FormInterface {
  uuid: string | null;
  username: string | null;
  email: string | null;
  roles: string[];
  language: SupportedLanguage;
  organization: Organization;

  constructor(jsonData: UserJson | null) {
    super(jsonData);
    this.uuid = jsonData?.uuid || null;
    this.username = jsonData?.username || null;
    this.email = jsonData?.email || null;
    this.roles = jsonData?.roles || [];
    this.language =
      jsonData?.language ||
      (localStorage.getItem(LocalStorageItem.Language) as SupportedLanguage) ||
      "nl";
    this.organization = new Organization(jsonData?.organization || null);
  }

  buildFormGroup(model?: User): UntypedFormGroup {
    const tempUser = model ? model : this;
    return new UntypedFormGroup({
      id: new UntypedFormControl(tempUser.id),
      uuid: new UntypedFormControl(tempUser.uuid),
      username: new UntypedFormControl(tempUser.username),
      email: new UntypedFormControl(tempUser.email),
      roles: tempUser.roles
        ? new UntypedFormArray(
            tempUser.roles.map((role) => new UntypedFormControl(role)),
          )
        : new UntypedFormArray([]),
      language: new UntypedFormControl(tempUser.language),
      organization: tempUser.organization
        ? tempUser.organization.buildFormGroup()
        : new Organization(null).buildFormGroup(),
    });
  }

  get isAdmin(): boolean {
    return this.roles.includes("ROLE_ADMIN");
  }

  get isDomainAdmin(): boolean {
    return this.roles.includes("ROLE_DOMAIN_ADMIN");
  }

  get isSwitchedUser(): boolean {
    return this.roles.includes("ROLE_PREVIOUS_ADMINISTRATOR");
  }

  trimFromGroup(formGroup: UntypedFormGroup): UntypedFormGroup {
    return formGroup;
  }
}

export enum UserFormMembers {
  Id = "id",
  Uuid = "uuid",
  Username = "username",
  Email = "email",
  Roles = "roles",
  Language = "language",
  Organization = "organization",
}

export interface UserJson extends BaseJson {
  uuid?: string;
  username?: string;
  email?: string;
  roles?: string[];
  language?: SupportedLanguage;
  organization?: OrganizationJson;
}

export type SupportedLanguage = "nl" | "en";
