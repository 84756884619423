import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "pascalToRegularCase" })
export class PascalToRegularCaseConverter implements PipeTransform {
  /*
   * This function mainly taking a Pascal Case string and return a regular string,
   * Exp:
   * Input: PascalCase
   * Return: Pascal Case
   * */
  transform(value: string): string {
    if (value != undefined) return value.replace(/([A-Z])/g, " $1").trim();
    return value;
  }
}
