import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HttpClientXsrfModule } from "@angular/common/http";
import { ApiPrefix } from "./services/api-prefix";
import { httpInterceptorProviders } from "./interceptors";

export const atmetaxAuthLink = `${ApiPrefix.value}oauth2/authorization/atmetax-reg`;

/**
 * This module is for classes used by app.module.
 *
 * Resources which are always loaded such as route guards, HTTP interceptors,
 * and application level services, such as the ThemeService and logging belong in this directory.
 *
 * https://angular.io/guide/styleguide#overall-structural-guidelines
 */
@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule, HttpClientXsrfModule],
  providers: [httpInterceptorProviders],
})
export class CoreModule {}
